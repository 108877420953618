<template>
  <div class="o-header" :class="{ 'o-header__scroll': isScrolled }">
    <div
      class="left-menu desktop-only"
      :class="{
        'menu-item__scroll': isScrolled,
        'menu-item__default': !isScrolled && isHomepage,
        'menu-item__browsing': !isHomepage,
      }"
    >
      <div
        v-for="(menu, i) in leftMenu"
        :key="i"
        class="menu-container"
        @click.stop="goTo(menu.link)"
      >
        <span class="menu-item">{{ $t(menu.name) }}</span>
        <div
          v-if="menu.children"
          class="menu-extend"
          :class="
            isScrolled || !isHomepage
              ? 'menu-extend__scroll'
              : 'menu-extend__default'
          "
        >
          <span
            v-for="(item, j) in menu.children"
            :key="j"
            class="menu-item__children"
            @click.stop="goTo(item.link, item.hash)"
          >
            {{ $t(item.name) }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="logo"
      :class="{ logo__scroll: isScrolled, logo__browsing: !isHomepage }"
    >
      <img
        :src="
          isScrolled || !isHomepage
            ? require('@/assets/logos/logo.png')
            : require('@/assets/logos/logo_w.png')
        "
        alt="logo"
        @click="goTo('/')"
      />
    </div>
    <div class="right-menu desktop-only">
      <div class="menu-container" @click="goTo('/my-account')">
        <span
          class="menu-item"
          :class="{
            'menu-item__scroll': isScrolled,
            'menu-item__default': !isScrolled && isHomepage,
            'menu-item__browsing': !isHomepage,
          }"
        >
          {{ $t(agentName) }}
        </span>
      </div>
      <span
        class="menu-item menu-item__highlight"
        @click="goTo('/consultation')"
      >
        {{ $t("Obțineți o Consultație GRATUITĂ") }}
      </span>
      <div
        class="lang"
        @mouseover="hoverLang = false"
        @mouseout="hoverLang = true"
      >
        <div class="lang__current">
          <!-- <img src="@/assets/flags/en.png"> -->
          <img :src="require(`@/assets/flags/${$i18n.locale}.png`)" />
        </div>
        <div class="lang__list">
          <div
            v-for="(lang, i) in filteredLangs"
            :key="i"
            class="lang__list--item"
            :class="{ hoverLang: hoverLang }"
            :style="
              `z-index: ${filteredLangs.length - i}; transform: translateY(${i *
                3}rem);`
            "
            @click="setLocale(lang.name)"
          >
            <img :src="lang.flag" :alt="lang.name" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="menuIcon mobile-only"
      :class="{ menuIcon__scroll: !isScrolled && isHomepage }"
      @click="openMenu = !openMenu"
    >
      <img src="@/assets/icons/menu.png" />
    </div>
    <transition name="fade">
      <div
        v-if="openMenu"
        class="mobileMenu mobile-only"
        :class="{ mobileMenu__active: openMenu }"
        @click.self="closeMenu"
      >
        <transition name="slide" appear @after-leave="handleSlideLeave">
          <div
            v-show="innerVisible"
            class="mobileMenu__content"
            :class="{
              'mobileMenu__content--active': openMenu,
              'mobileMenu__content--scroll': isScrolled,
              'mobileMenu__content--noScroll': !isScrolled,
            }"
          >
            <div class="mobileMenu__content--top">
              <h4>{{ $t("Menu") }}</h4>
              <img src="@/assets/icons/close.png" @click.self="closeMenu" />
            </div>
            <div class="mobileMenu__content--middle">
              <p
                v-for="(item, i) in leftMenu"
                :key="i"
                @click="goTo(item.link), toggleMenu()"
              >
                {{ $t(item.name) }}
              </p>
            </div>
            <span
              class="menu-item menu-item__highlight menu-item__highlight--mobile"
              @click="goTo('/consultation')"
            >
              {{ $t("Obțineți o Consultație GRATUITĂ") }}
            </span>
            <div class="mobileMenu__content--bottom">
              <p @click="goTo('/my-account'), toggleMenu()">
                {{ $t(agentName) }}
              </p>
              <div class="mobileMenu__content--bottom-langs">
                <div
                  v-for="(lang, i) in langs"
                  :key="i"
                  class="mobileMenu__content--bottom-langs-lang"
                  @click="setLocale(lang.name), toggleMenu()"
                >
                  <img :src="lang.flag" :alt="lang.name" />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      agentName: "Autentificare Agent",
      hoverLang: true,
      openMenu: false,
      innerVisible: false,
      langs: [
        {
          name: "ro",
          flag: require("@/assets/flags/ro.png"),
        },
        {
          name: "en",
          flag: require("@/assets/flags/en.png"),
        },
        // {
        //   name: "ru",
        //   flag: require("@/assets/flags/ru.png"),
        // },
        // {
        //   name: "de",
        //   flag: require("@/assets/flags/de.png"),
        // },
      ],
      leftMenu: [
        {
          name: "Asigurări",
          link: "/insurances",
          children: [
            {
              name: "Private",
              link: "Insurances",
              hash: "#personal-insurance",
            },
            {
              name: "Firme",
              link: "Insurances",
              hash: "#business-insurance",
            },
          ],
        },
        {
          name: "Credite",
          link: "/loans",
        },
        {
          name: "Dezvoltare Financiară",
          link: "/investments",
        },
        {
          name: "Carieră",
          link: "/career",
        },
        {
          name: "Blog",
          link: "/blog",
        },
      ],
      windowTop: 0,
    };
  },
  watch: {
    openMenu(val) {
      if (val) {
        this.innerVisible = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("storage", this.setAgent);
    window.dispatchEvent(new Event("storage"));
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("storage", this.setAgent);
  },
  computed: {
    isScrolled() {
      return this.windowTop > 50;
    },
    isHomepage() {
      return this.$route.name == "Home";
    },
    filteredLangs() {
      return this.langs.filter((lang) => lang.name !== this.$i18n.locale);
    },
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY;
    },
    goTo(link, hash) {
      const toRoute = `/${this.$i18n.locale}${link}`;
      if (hash) {
        this.$router.push({ name: link, hash: hash });
        return;
      } else {
        this.$router.push(toRoute);
        return;
      }
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push({ params: { lang: locale } });
    },
    setAgent() {
      let user = JSON.parse(localStorage.getItem("user"));
      user
        ? (this.agentName = `${user.firstName} ${user.lastName}`)
        : (this.agentName = "Autentificare Agent");
    },
    toggleMenu() {
      this.openMenu = !this.openMenu;
    },
    handleSlideLeave() {
      if (this.openMenu) {
        this.openMenu = false;
      }
    },
    closeMenu() {
      this.innerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-header {
  transition: all 0.3s ease;
  @include for-mobile {
    padding: var(--spacer-sm);
    width: calc(100% - 2rem);
    align-items: center;
  }
  &__scroll {
    background: var(--c-white);
    @include for-desktop {
      padding: var(--spacer-xs) var(--spacer-2xl) !important;
      box-shadow: 0 0 20px #d9e3ee;
    }
  }
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  width: calc(100% - 10rem);
  padding: var(--spacer-sm) var(--spacer-2xl);
  transition: 0.3s ease;
  .left-menu {
    display: flex;
    align-items: center;
    flex: 1 1 30%;
    .menu-item {
      margin: 0 var(--spacer-sm);
      &:first-child {
        margin: 0 var(--spacer-sm) 0 0;
      }
    }
  }
  .right-menu {
    display: flex;
    align-items: center;
    flex: 1 1 30%;
    justify-content: flex-end;
    .menu-item {
      margin: 0 var(--spacer-sm);
      &:last-child {
        margin: 0 0 0 var(--spacer-sm);
      }
    }
  }
  .logo {
    position: relative;
    max-width: 14rem;
    height: 4rem;
    flex: 1 1 40%;
    transition: all 0.3s ease;
    @include for-mobile {
      max-width: 11rem;
      height: 3.25rem;
    }
    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
    @include for-desktop {
      &__scroll {
        max-width: 10.5rem !important;
        height: 3rem !important;
      }
    }
  }
  .menu-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    &:hover {
      .menu-item {
        text-decoration: underline;
      }
      .menu-extend {
        display: block;
        opacity: 1;
      }
    }
  }
  .menu-extend {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 10rem;
    padding: var(--spacer-sm) 0 var(--spacer-base);
    border: 1px solid var(--c-white);
    backdrop-filter: blur(40px);
    opacity: 0;
    transition: 0.3s ease;
    &__default {
      background: transparent;
      color: var(--c-white);
    }
    &__scroll {
      background: var(--c-white);
      color: var(--c-text);
      box-shadow: 0 10px 10px #d9e3ee50;
    }
    &__browsing {
      background: var(--c-white);
      color: var(--c-white);
    }
  }
  .menu-item {
    &__default {
      color: var(--c-white);
    }
    &__scroll,
    &__browsing {
      color: var(--c-text);
    }
    font-size: var(--font-sm);
    font-family: var(--font-family-secondary);
    padding: 0.65rem 1rem;
    transition: 0.3s ease;
    &__highlight {
      border: 1px solid var(--c-primary-lighten);
      border-radius: 10px;
      color: var(--c-white) !important;
      background: var(--c-primary-lighten);
      cursor: pointer;
      &:hover {
        text-decoration: none !important;
        // background: rgba(0, 142, 230, 0.2);
        background: var(--c-primary-darken);
        border-color: var(--c-primary-darken);
      }
      &--mobile {
        @include for-mobile {
          margin: 0 var(--spacer-base);
          padding: var(--spacer-sm);
          font-size: var(--font-base);
        }
      }
    }
    &__children {
      display: block;
      font-size: var(--font-sm);
      font-family: var(--font-family-secondary);
      cursor: pointer;
      padding: 0.65rem 1rem;
      transition: 0.3s ease;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.lang {
  position: relative;
  &:hover {
    .lang__current {
      &:before {
        background: rgba(255, 255, 255, 0.35);
      }
    }
    .lang__list {
      top: 100%;
      &--item {
        transform: translateY(0);
        & img {
          top: 1rem;
        }
        &:before {
          top: 1rem;
        }
      }
    }
  }
  &__current {
    position: relative;
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // object-fit: cover;
      cursor: pointer;
      z-index: 10;
    }
    &:before {
      content: "";
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 50%;
      transition: 0.3s ease;
      cursor: pointer;
    }
  }
  &__list {
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease;
    &--item {
      position: absolute;
      max-width: 2rem;
      max-height: 2rem;
      margin: 0 1rem;
      padding: 2rem 1rem;
      transition: 0.3s ease;
      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // object-fit: cover;
        cursor: pointer;
        transition: 0.3s ease;
      }
      &:before {
        content: "";
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s ease;
      }
      &:hover {
        &:before {
          background: rgba(255, 255, 255, 0.35);
        }
      }
    }
  }
}
.hoverLang {
  transform: none !important;
  margin: 0 1rem !important;
}
.menuIcon {
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    filter: invert(30%) sepia(82%) saturate(4318%) hue-rotate(186deg)
      brightness(87%) contrast(102%);
  }
  &__scroll,
  &__browsing {
    img {
      filter: invert(1);
    }
  }
}
.mobileMenu {
  position: absolute;
  right: -80vw;
  transition: 0.5s ease;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // opacity: 0;
  // &__active {
  // 	opacity: 1 !important;
  // }
  &__content {
    position: absolute;
    height: 87vh;
    width: 77vw;
    right: -77vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--scroll {
      background: var(--c-white);
    }
    &--noScroll {
      background: var(--c-white);
      // backdrop-filter: blur(40px);
    }
    &--active {
      right: 0 !important;
    }

    &--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      padding: 0 var(--spacer-lg);
      background: var(--c-primary-darken);
      color: var(--c-white);
      h4 {
        font-family: var(--font-family-secondary);
        font-size: var(--font-xl);
        font-weight: var(--font-medium);
        margin: 0;
      }
      img {
        width: 1.25rem;
        height: 1.25rem;
        filter: invert(1);
      }
    }
    &--middle {
      padding: var(--spacer-base) var(--spacer-base);
      p {
        font-family: var(--font-family-secondary);
        font-weight: var(--font-normal);
        font-size: var(--font-base);
        margin: 0;
        padding: var(--spacer-sm) var(--spacer-xs);
        border: solid var(--c-light);
        border-width: 0 0 1px 0;
        transition: 0.3s ease;
        &:last-child {
          border-width: 0;
        }
        &:hover {
          background: var(--c-light);
        }
      }
    }
    &--bottom {
      padding: var(--spacer-2xl) var(--spacer-base) var(--spacer-xl);
      p {
        font-family: var(--font-family-secondary);
        font-weight: var(--font-normal);
        font-size: var(--font-base);
        margin: 0;
        padding: var(--spacer-sm) var(--spacer-xs);
        border: solid var(--c-light);
        border-width: 0 0 1px 0;
        transition: 0.3s ease;
        &:hover {
          background: var(--c-light);
        }
      }
      &-langs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: var(--spacer-xl) 0 0;
        &-lang {
          position: relative;
          width: 2.25rem;
          height: 2.25rem;
          margin: var(--spacer-sm) var(--spacer-xs);
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
.slide-enter-active {
  transition: transform 0.5s ease;
}

.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to,
.slide-leave-active {
  transform: translateX(77vw);
}

// @keyframes slide {
//   0% {
//     transform: translateX(77vw);
//   }

//   50% {
//     transform: translateX(77vw);
//   }

//   100% {
//     transform: translateX(0);
//   }
// }
</style>
