var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"o-header",class:{ 'o-header__scroll': _vm.isScrolled }},[_c('div',{staticClass:"left-menu desktop-only",class:{
      'menu-item__scroll': _vm.isScrolled,
      'menu-item__default': !_vm.isScrolled && _vm.isHomepage,
      'menu-item__browsing': !_vm.isHomepage,
    }},_vm._l((_vm.leftMenu),function(menu,i){return _c('div',{key:i,staticClass:"menu-container",on:{"click":function($event){$event.stopPropagation();return _vm.goTo(menu.link)}}},[_c('span',{staticClass:"menu-item"},[_vm._v(_vm._s(_vm.$t(menu.name)))]),(menu.children)?_c('div',{staticClass:"menu-extend",class:_vm.isScrolled || !_vm.isHomepage
            ? 'menu-extend__scroll'
            : 'menu-extend__default'},_vm._l((menu.children),function(item,j){return _c('span',{key:j,staticClass:"menu-item__children",on:{"click":function($event){$event.stopPropagation();return _vm.goTo(item.link, item.hash)}}},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])}),0):_vm._e()])}),0),_c('div',{staticClass:"logo",class:{ logo__scroll: _vm.isScrolled, logo__browsing: !_vm.isHomepage }},[_c('img',{attrs:{"src":_vm.isScrolled || !_vm.isHomepage
          ? require('@/assets/logos/logo.png')
          : require('@/assets/logos/logo_w.png'),"alt":"logo"},on:{"click":function($event){return _vm.goTo('/')}}})]),_c('div',{staticClass:"right-menu desktop-only"},[_c('div',{staticClass:"menu-container",on:{"click":function($event){return _vm.goTo('/my-account')}}},[_c('span',{staticClass:"menu-item",class:{
          'menu-item__scroll': _vm.isScrolled,
          'menu-item__default': !_vm.isScrolled && _vm.isHomepage,
          'menu-item__browsing': !_vm.isHomepage,
        }},[_vm._v(" "+_vm._s(_vm.$t(_vm.agentName))+" ")])]),_c('span',{staticClass:"menu-item menu-item__highlight",on:{"click":function($event){return _vm.goTo('/consultation')}}},[_vm._v(" "+_vm._s(_vm.$t("Obțineți o Consultație GRATUITĂ"))+" ")]),_c('div',{staticClass:"lang",on:{"mouseover":function($event){_vm.hoverLang = false},"mouseout":function($event){_vm.hoverLang = true}}},[_c('div',{staticClass:"lang__current"},[_c('img',{attrs:{"src":require(`@/assets/flags/${_vm.$i18n.locale}.png`)}})]),_c('div',{staticClass:"lang__list"},_vm._l((_vm.filteredLangs),function(lang,i){return _c('div',{key:i,staticClass:"lang__list--item",class:{ hoverLang: _vm.hoverLang },style:(`z-index: ${_vm.filteredLangs.length - i}; transform: translateY(${i *
              3}rem);`),on:{"click":function($event){return _vm.setLocale(lang.name)}}},[_c('img',{attrs:{"src":lang.flag,"alt":lang.name}})])}),0)])]),_c('div',{staticClass:"menuIcon mobile-only",class:{ menuIcon__scroll: !_vm.isScrolled && _vm.isHomepage },on:{"click":function($event){_vm.openMenu = !_vm.openMenu}}},[_c('img',{attrs:{"src":require("@/assets/icons/menu.png")}})]),_c('transition',{attrs:{"name":"fade"}},[(_vm.openMenu)?_c('div',{staticClass:"mobileMenu mobile-only",class:{ mobileMenu__active: _vm.openMenu },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeMenu.apply(null, arguments)}}},[_c('transition',{attrs:{"name":"slide","appear":""},on:{"after-leave":_vm.handleSlideLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.innerVisible),expression:"innerVisible"}],staticClass:"mobileMenu__content",class:{
            'mobileMenu__content--active': _vm.openMenu,
            'mobileMenu__content--scroll': _vm.isScrolled,
            'mobileMenu__content--noScroll': !_vm.isScrolled,
          }},[_c('div',{staticClass:"mobileMenu__content--top"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Menu")))]),_c('img',{attrs:{"src":require("@/assets/icons/close.png")},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeMenu.apply(null, arguments)}}})]),_c('div',{staticClass:"mobileMenu__content--middle"},_vm._l((_vm.leftMenu),function(item,i){return _c('p',{key:i,on:{"click":function($event){_vm.goTo(item.link), _vm.toggleMenu()}}},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])}),0),_c('span',{staticClass:"menu-item menu-item__highlight menu-item__highlight--mobile",on:{"click":function($event){return _vm.goTo('/consultation')}}},[_vm._v(" "+_vm._s(_vm.$t("Obțineți o Consultație GRATUITĂ"))+" ")]),_c('div',{staticClass:"mobileMenu__content--bottom"},[_c('p',{on:{"click":function($event){_vm.goTo('/my-account'), _vm.toggleMenu()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.agentName))+" ")]),_c('div',{staticClass:"mobileMenu__content--bottom-langs"},_vm._l((_vm.langs),function(lang,i){return _c('div',{key:i,staticClass:"mobileMenu__content--bottom-langs-lang",on:{"click":function($event){_vm.setLocale(lang.name), _vm.toggleMenu()}}},[_c('img',{attrs:{"src":lang.flag,"alt":lang.name}})])}),0)])])])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }